<template>
    <div class="bmi-result">
        <div class="user-bmi-current">
            <p>
                {{ $t("At this moment you have a") }}
                <strong>
                    {{
                        $t("BMI of {bmi}.", {
                            bmi: bmiDisplayValue,
                        })
                    }}
                </strong>
                {{ $t("That puts you in the category") }}
                <strong>{{ health }}</strong>
            </p>
        </div>
        <ul class="user-bmi-list">
            <li>
                <strong>{{ $t("BMI {eq}", { eq: "&lt; 18,5" }) }}</strong>
                = {{ $t("underweight") }}
            </li>
            <li>
                <strong>{{
                    $t("BMI of {from} to {to}", {
                        from: NumberToCommaString(18.5),
                        to: 25,
                    })
                }}</strong>
                = {{ $t("healthy weight") }}
            </li>
            <li>
                <strong>{{
                    $t("BMI of {from} to {to}", { from: 25, to: 30 })
                }}</strong>
                = {{ $t("overweight") }}
            </li>
            <li>
                <strong>{{
                    $t("BMI of {from} to {to}", { from: 30, to: 35 })
                }}</strong>
                = {{ $t("fairly overweight") }}
            </li>
            <li>
                <strong>{{ $t("BMI {eq}", { eq: "&gt; 35" }) }}</strong>
                = {{ $t("extremely overweight") }}
            </li>
        </ul>
        <div class="user-bmi-info">
            <p>
                {{ $t("You have a healthy weight if your BMI is between") }}
                <strong>{{
                    $t("{bmi_min} and {bmi_max}.", {
                        bmi_min: NumberToCommaString(18.5),
                        bmi_max: NumberToCommaString(25),
                    })
                }}</strong>
                {{ $t("This means that with a") }}
                <strong>{{ $t("length of") }} {{ length }}</strong>
                {{ $t("you can weigh between") }}
                <strong>
                    {{
                        $t("{from} and {to}", {
                            from: NumberToCommaString(weight_min),
                            to: NumberToCommaString(weight_max),
                        })
                    }}
                </strong>
                {{ $t("kilos to have a healthy weight.") }}
            </p>
        </div>
    </div>
</template>

<script>
import PG from "@/lib/PG";
import TypeChecker from "@/lib/TypeChecker";
import { NumberToCommaString } from "@/lib/Misc";

export default {
    props: {
        bmi: {
            type: [Number, String],
            required: true,
        },
        length: {
            type: Number,
            required: true,
        },
    },
    computed: {
        health() {
            return PG.GetBMIClassification(this.bmi);
        },
        weight_min() {
            return PG.GetRequiredWeightForBmi(this.length, 18.5);
        },
        weight_max() {
            return PG.GetRequiredWeightForBmi(this.length, 25);
        },
        bmiDisplayValue() {
            if (TypeChecker.isNumber(this.bmi)) {
                return NumberToCommaString(this.bmi);
            }

            return this.bmi;
        },
    },
    methods: {
        NumberToCommaString
    }
};
</script>

<style lang="scss">
@import ".";
</style>
