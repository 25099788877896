<template>
    <div class="block" v-if="getUser">
        <div class="block-title">
            <h2>{{ $t("My details") }}</h2>
        </div>
        <div class="block-content">
            <div class="user-details">
                <section class="user-total" v-if="totalLostWeight > 0">
                    <label>{{ $t("Total lost weight").toUpperCase() }}</label>
                    <div class="value">
                        <strong>
                            {{ totalLostWeightDisplayed }}
                            <small>kg</small>
                        </strong>
                        <span>
                            {{
                                $t("Since {date} ({weight} kg)", {
                                    date: userDietStart,
                                    weight: startWeightDisplayed,
                                })
                            }}
                        </span>
                    </div>
                </section>
                <section class="user-calories">
                    <label>{{ $t("Calorie need").toUpperCase() }}</label>
                    <div class="value">
                        <strong>{{ getUser.getCalorieRequirement() }}</strong>
                        <span>
                            -
                            {{
                                getUser.getCalorieRequirement() -
                                getUser.getRecommendedCalorieRequirement()
                            }}
                            =
                            {{ getUser.getRecommendedCalorieRequirement() }}
                            {{ $t("Kcal to lose weight") }}
                        </span>
                    </div>
                    <button
                        class="btn -link"
                        @click="$emit('click-calorie-requirement')">
                        {{ $t("More info") }}
                    </button>
                </section>
                <section class="user-bmi">
                    <label>{{ $t("BMI (Body mass index)") }}</label>
                    <div class="value">
                        <strong>{{ getUser.getBMI() }}</strong>
                        <span>{{ BMIClassification }}</span>
                        <span>({{
                                $t("{kg} kg", {
                                    kg: NumberToCommaString(parseFloat(
                                        getUser.getCurrentWeight()
                                    )),
                                })
                            }})</span>
                    </div>
                    <button class="btn -link" @click="$emit('click-user-bmi')">
                        {{ $t("More info") }}
                    </button>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import DateTime from "@/lib/misc/DateTime";
import PG from "@/lib/PG";
import { NumberToCommaString } from "@/lib/Misc";

export default {
    computed: {
        ...mapGetters("user", ["getUser"]),

        userDietStart() {
            let start_at = this.getUser.getCustomerSetting("diet_start_at");

            if (!start_at || !start_at.value.date) {
                return null;
            }

            return DateTime.ConvertDateToHumanReadable(
                DateTime.Date(start_at.value.date),
                false
            );
        },

        totalLostWeight() {
            let lost_weight =
                this.getUser.getStartWeight() - this.getUser.getCurrentWeight();

            return lost_weight;
        },

        startWeightDisplayed() {
            return this.getUser.getStartWeight().replace(".", ",");
        },

        totalLostWeightDisplayed() {
            let lost_weight =
                this.getUser.getStartWeight() - this.getUser.getCurrentWeight();
            lost_weight = lost_weight.toFixed(1);

            if (lost_weight.includes(".")) {
                lost_weight = lost_weight.replace(".", ",");
            }

            return lost_weight;
        },

        BMIClassification() {
            const bmiString = this.getUser.getBMI();
            const bmi = parseInt(bmiString);

            return PG.GetBMIClassification(bmi);
        },
    },
    methods: {
        NumberToCommaString
    }
};
</script>

<style lang="scss">
@import ".";
</style>
