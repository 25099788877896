<template>
    <div
        class="modal modal-alert">
        <div class="modal-wrapper">
            <div class="modal-content">
                <h2>{{ title }}</h2>
                <p>{{ content }}</p>
            </div>
            <div class="btn-group">
                <button class="btn -cancel -transparent" @click="$emit('cancel')">{{ CancelTitle }}</button>
                <button class="btn -submit -transparent" @click="$emit('submit')">{{ SubmitTitle }}</button>
            </div>
        </div>
        <div class="modal-backdrop" @click="$emit('close')"></div>
    </div>
</template>

<script>
import Translations from "@/lib/Translations";
const $t = Translations.Get;

export default {
    props: {
        title: String,
        content: String,
        CancelTitle: {
            type: String,
            default: $t("Cancel")
        },
        SubmitTitle: {
            type: String,
            default: $t("Ok")
        }
    },
    name: "ModalAlert",
};
</script>

<style lang="scss">
@import "index";
</style>
