import DataObject from "@/models/DataObject";
import {DateTime} from "@/lib/Misc";

import config from "@/config";

export default class PlannedDayMenu extends DataObject {
    constructor(data) {
        super(data);
    }

    getPlannedAt() {
        const plannedAt = DateTime.Date(this.getData("planned_at"));

        plannedAt.setHours(0);
        plannedAt.setSeconds(0);
        plannedAt.setMinutes(0);
        plannedAt.setMilliseconds(0);

        return plannedAt;
    }

    getFormattedPlannedAtDate() {
        return DateTime.ConvertDateToHumanReadable(this.getPlannedAt());
    }

    getMedia(size = null) {
        let media = this.getData("media");

        if (!size) {
            return media;
        }

        if (!media[size]) {
            //TODO should return placeholder
            return "";
        }

        return new URL(media[size], config.media_url).toString();
    }
}
