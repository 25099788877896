<template>
    <modal
        :title="$t('Weight logs')"
        class="weight-log-modal"
        @close="$emit('close')">
        <template v-slot:modal-header-button-start>
            <button @click="$emit('close')" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>

        <div class="btn -transparent -add" @click="$emit('add')">
            <icon-plus />
            {{ $t("Add weightlog") }}
        </div>

        <div class="weight-log-modal-delete" v-if="logs.length">
            <button class="btn -link -delete-all" @click="modal_alert_remove_confirm = true">
                {{ $t("Remove all weightlog") }}
            </button>

            <modal-alert
                v-if="modal_alert_remove_confirm"
                @close="modal_alert_remove_confirm = false"
                @cancel="modal_alert_remove_confirm = false"
                @submit="removeAllWeightLogs"
                :title="$t('Watch out!')"
                :content="$t('Are you sure you want to remove all of your weight logs?')"
                :submit-title="$t('Continue')"
            />
        </div>

        <div>
            <div v-for="log in logs" :key="log.getId()" class="log">
                <swipable @swipe-left="removeLog(log.getId())">
                    <div class="value">
                        <strong>{{ log.getFormattedWeight() }}</strong>
                        <p>{{ log.getFormattedDate(true) }}</p>
                    </div>
                </swipable>

                <div class="btn -transparent -icon-only -delete" @click="removeLog(log.getId())">
                    <icon-trash />
                </div>
            </div>

            <div class="view-all text-center" v-if="current_page !== total_pages">
                <button
                    class="btn -accent -view-all"
                    @click="loadNextPage">
                    {{ $t("Load more weight logs") }}
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import WeightLogs from "@/lib/WeightLogs";
import DateTime from "@/lib/misc/DateTime";
import Modal from "@/components/Modal";
import ModalAlert from "@/components/Modal/Alert";
import Swipable from "@/components/Swipable";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import iconPlus from "@/assets/icons/icon-plus.svg?inline";
import iconTrash from "@/assets/icons/icon-trash.svg?inline";

export default {
    components: {
        Modal,
        ModalAlert,
        Swipable,
        iconLeft,
        iconPlus,
        iconTrash
    },
    data() {
        return {
            modal_alert_remove_confirm: false,
            current_page: 0,
            total_pages: 1,
            logs: []
        };
    },
    methods: {
        async loadNextPage() {
            this.current_page++;
            let rows = (await WeightLogs.GetLogByPage(this.current_page))
                .sort((a, b) => DateTime.Date(b.getDay()) - DateTime.Date(a.getDay()));

            this.logs = this.logs.concat(rows);

            this.total_pages = WeightLogs.total_pages;
        },
        async removeLog(logId) {
            await WeightLogs.RemoveLog(logId);

            this.logs = this.logs.filter(log => log.getId() != logId);

            this.$emit("refresh");
            this.$toast({ type: "success", message: this.$t("Weightlog deleted") });
        },
        async removeAllWeightLogs() {
            let resp = await WeightLogs.RemoveAllLogs();

            if (resp.status === 200) {
                this.logs = [];
            }

            this.modal_alert_remove_confirm = false;
            this.$emit("refresh");
        }
    },
    async mounted() {
        await this.loadNextPage();
    }
};
</script>

<style lang="scss">
    @import ".";
</style>
