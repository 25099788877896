<template>
    <div class="block" v-if="favoriteDayMenus.length">
        <div class="block-title">
            <h2>{{ $t("My favorite daymenus") }}</h2>
        </div>
        <div class="block-content -shadow">
            <card
                class="-list"
                v-for="favoriteDayMenu in favoriteDayMenus"
                :key="favoriteDayMenu.getId()"
                :url-path="`/dag-menu/${favoriteDayMenu.getSlug()}`"
                :title="favoriteDayMenu.getTitle()"
                :image-url="favoriteDayMenu.getMedia('small')"
            >
                <template v-slot:card-actions>
                    <button
                        @click="removeFavorite(favoriteDayMenu)"
                        class="btn -icon -icon-only"
                    >
                        <iconTrash />
                    </button>
                </template>
            </card>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import FavoriteHelper from "@/lib/FavoriteHelper";

import Card from "@/components/Card";
import iconTrash from "@/assets/icons/icon-trash.svg?inline";

export default {
    components: {
        Card,
        iconTrash
    },
    computed: {
        ...mapState("favorites", ["favoriteDayMenus"]),
    },
    methods: {
        removeFavorite(favoriteObject) {
            FavoriteHelper.onFavoriteToggle(favoriteObject);
        }
    },
    async mounted() {
        if (!FavoriteHelper.hasDayMenu) {
            await FavoriteHelper.initDayMenus();
        }
    }
};
</script>

<style></style>
