<template>
    <modal
        @close="$emit('close')"
        :title="$t('Add weightlog')"
        class="-on-theme weight-log"
    >

        <template v-slot:modal-header-button-end>
            <button
                @click="$emit('close')"
                class="btn -icon -icon-only"
            >
                <iconClose />
            </button>
        </template>

        <form method="dialog">
            <input type="number" step="0.01" :placeholder="$t('Weight')" v-model="weight" />

            <div class="weight-date">
                <input type="number" :placeholder="$t('Day')" max="31" v-model="day" />
                <input type="number" :placeholder="$t('Month')" max="12" v-model="month" />
                <input type="number" :placeholder="$t('Year')" v-model="year" />
            </div>

            <div class="btn-wrapper">
                <button class="btn -accent -lg" @click="addLog">{{ $t("Add") }}</button>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "@/components/Modal";
import WeightLogs from "@/lib/WeightLogs";
import DateTime from "@/lib/misc/DateTime";

import iconClose from "@/assets/icons/icon-close.svg?inline";

export default {
    components: {
        Modal,
        iconClose
    },
    data() {
        return {
            weight: null,
            day: new Date().getDate(),
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
        };
    },
    methods: {
        async addLog() {
            if (!this.weight || !this.day || !this.month || !this.year) {
                this.$toast({ type: "error", message: this.$t("Please first fill in all fields") });
                return;
            }

            let timestamp = DateTime.ParseDate(this.day, this.month, this.year);
            let resp = await WeightLogs.AddLog(timestamp, this.weight);

            if (typeof resp.error === "object") {
                let err = Object.values(resp.error)[0];

                if (err.length) {
                    err = err[0];
                }

                this.$toast({ type: "error", message: this.$t(err) });
                return;
            }

            if (resp.data === 201) {
                this.$toast({ type: "success", message: this.$t("Succesfully added weight log") });
                this.$emit("refresh");
                this.$emit("close");
            }
        }
    }
};
</script>

<style lang="scss">
@import ".";
</style>
