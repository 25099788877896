<template>
    <div class="block" v-if="flattenDayMenus.length">
        <div class="block-title">
            <h2>{{ $t("My planned daymenus") }}</h2>
        </div>
        <div class="block-content">
            <card
                class="-list"
                v-for="plannedDayMenu in flattenDayMenus"
                :key="plannedDayMenu.getId()"
                :url-path="`/dag-menu/${plannedDayMenu.getDayMenuSlug()}`"
                :title="plannedDayMenu.getDayMenuTitle()"
                :image-url="plannedDayMenu.getMedia('small')"
                :calendar-text="plannedDayMenu.getFormattedPlannedAtDate()"
            >
                <template v-slot:card-actions>
                    <button
                        @click="unplanDayMenu(plannedDayMenu)"
                        class="btn -icon -icon-only"
                    >
                        <iconTrash />
                    </button>
                </template>
            </card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import PlannedDayMenuApi from "@/lib/api/PlannedDayMenu";
import Card from "@/components/Card";

import iconTrash from "@/assets/icons/icon-trash.svg?inline";

export default {
    data() {
        return {
            flattenDayMenus: [],
        };
    },
    components: {
        Card,
        iconTrash
    },
    methods: {
        ...mapMutations("plannedDayMenus", ["setPlannedDayMenus"]),
        ...mapActions("plannedDayMenus", [
            "setSelectDate",
            "removePlannedDayMenusFromList"
        ]),
        async unplanDayMenu(plannedDayMenu) {
            await PlannedDayMenuApi.UnplanDayMenu(plannedDayMenu.getId());

            this.flattenDayMenus = this.flattenDayMenus.filter((item) => {
                return (
                    plannedDayMenu.getPlannedAt().getTime() !==
                    item.getPlannedAt().getTime()
                );
            });

            this.removePlannedDayMenusFromList({ plannedDayMenu });
            this.setSelectDate();
        }
    },
    computed: {
        ...mapState("plannedDayMenus", ["plannedDayMenus"]),
    },
    async mounted() {
        if (!this.plannedDayMenus) {
            const menus = await PlannedDayMenuApi.GetAll();
            this.setPlannedDayMenus(menus);
            this.setSelectDate();
        }

        this.flattenDayMenus = this.plannedDayMenus;

        let menus = [];
        for (let monthYear of this.flattenDayMenus) {
            menus = menus.concat(
                monthYear.plannedDayMenus
            );
        }

        this.flattenDayMenus = menus;
    }
};
</script>

<style></style>
