<template>
    <div class="block">
        <div class="block-title">
            <h2>{{ $t("Progress") }}</h2>
        </div>
        <div class="block-content">
            <div class="progress">
                <div class="progress-bar" v-if="getUser">
                    <div
                        class="labels"
                        v-if="
                            getUser.getCustomerSetting('start_weight').value &&
                            getUser.getCustomerSetting('end_weight').value
                        ">
                        <div class="start">
                            {{ NumberToCommaString(getUser.getCustomerSetting("start_weight").value) }}
                            KG
                        </div>
                        <div class="end">
                            {{ NumberToCommaString(getUser.getCustomerSetting("end_weight").value) }}
                            KG
                        </div>
                    </div>

                    <div class="progress-bar-line">
                        <div
                            class="active"
                            :style="{ width: `${getProgress}%` }"
                        >
                            <div class="tooltip">
                                {{ getProgress.toFixed(1) }}%
                            </div>
                        </div>
                    </div>
                </div>
                <line-chart
                    class="progress-chart"
                    :chart-data="chartDataSet"
                    :options="chartOptions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import WeightLogs from "@/lib/WeightLogs";
import DateTime from "@/lib/misc/DateTime";
import { NumberToCommaString } from "@/lib/Misc";

// Prereq for chartjs
Chart.register(...registerables);

export default {
    components: {
        LineChart,
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    xAxes: {
                        display: true,

                        ticks: {
                            maxTicksLimit: 12,
                        },
                    },
                },
            },
            logs: [],
        };
    },
    async mounted() {
        let logs = [];
        let page = 1;

        // Loop through all pages and add them to the logs array
        while (page <= 2) {
            const pageLogs = await WeightLogs.GetLogByPage(page);

            if (pageLogs.length === 0) {
                break;
            }

            logs.push(...pageLogs);
            page++;
        }

        // Limit visible logs to only the last 25
        logs = logs.slice(0, 25);

        this.logs = logs.sort((a, b) => {
            return DateTime.Date(a.getDay()) - DateTime.Date(b.getDay());
        });
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        getRecentLogs() {
            return this.logs.slice(0, 3);
        },
        chartDataSet() {
            return {
                labels: this.logs.map((i) => {
                    let date = DateTime.Date(i.getDay());

                    return [
                        date.getDate(),
                        this.$t(DateTime.GetShortMonthName(date.getMonth())) +
                            ".",
                        date.getFullYear(),
                    ]
                        .join(" ")
                        .toLowerCase();
                }),
                datasets: [
                    {
                        data: this.logs.map((i) => i.getWeight()),

                        backgroundColor: "rgba(184,233,134,0.30)",
                        borderColor: "#17A31F",
                        pointBackgroundColor: "#B8E986",
                        pointBorderColor: "#17A31F",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgba(148,159,177,0.8)",
                        lineTension: 0.5,
                        fill: {
                            target: "origin",
                            above: "rgba(184,233,134,0.30)",
                        },
                    },
                ],
            };
        },
        getProgress() {
            if (!this.getUser || this.logs.length === 0) {
                return 100;
            }

            const start_weight =
                this.getUser.getCustomerSetting("start_weight").value;
            const end_weight =
                this.getUser.getCustomerSetting("end_weight").value + 0.0001;

            let goal = start_weight - end_weight;
            let current = this.logs[this.logs.length - 1].getWeight();
            let loss = start_weight - current;
            let progress = loss / (goal / 100);

            if (progress > 100) {
                progress = 100;
            }

            if (progress < 0) {
                progress = 0;
            }

            return progress;
        },
    },
    methods: {
        NumberToCommaString
    }
};
</script>

<style lang="scss">
@import ".";
</style>
