<template>
    <modal
        :title="$t('Change Password')"
        class="change-password-modal"
        v-if="getUser"
    >
        <template v-slot:modal-header-button-start>
            <button @click="$emit('close')" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>

        <div class="fields">
            <div class="field">
                <label for="password">
                    {{ $t("Current Password") }}
                </label>
                <div :class="`input ${errors['password'] ? 'has-error' : ''}`">
                    <div class="input-content">
                        <input
                            :type="show_password ? 'text' : 'password'"
                            id="password"
                            v-model="values.password"
                            :placeholder="$t('Enter your current password')"
                        />
                    </div>
                </div>
            </div>
            <fieldset class="fields">
                <div class="field">
                    <label for="newPassword">{{ $t("New Password") }}</label>
                    <div :class="`input ${errors['newPassword'] ? 'has-error' : ''}`">
                        <div class="input-content">
                            <input
                                :type="show_password ? 'text' : 'password'"
                                id="newPassword"
                                v-model="values.newPassword"
                                :placeholder="$t('Enter your new password')"
                            />
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div :class="`input ${errors['newPassword'] ? 'has-error' : ''}`">
                        <div class="input-content">
                            <input
                                :type="show_password ? 'text' : 'password'"
                                v-model="values.newPassword_confirmation"
                                :placeholder="$t('Enter your new password again')"
                            />
                        </div>
                        <button
                            type="button"
                            class="show-password"
                            @click="show_password = !show_password">
                            {{
                                !show_password ?
                                    $t("Show password") :
                                    $t("Hide password")
                            }}
                            <iconEye v-if="!show_password" />
                            <iconEyeOff v-else />
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>

        <div class="help">
            <h3>{{ $t("Your new password requires:") }}</h3>
            <ul class="checklist">
                <li><iconChevronRight /> {{ $t("At least 8 characters") }}</li>
                <li><iconChevronRight /> {{ $t("Uppercase and lowercase characters") }}</li>
                <li><iconChevronRight /> {{ $t("At least contain 1 special character") }}</li>
            </ul>
        </div>

        <div class="flex justify-center mt-10">
            <button class="btn -accent -lg" @click="save">
                <span>{{ $t("Change Password") }}</span>
            </button>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from "vuex";
import UsersApi from "@/lib/api/Users";
import Validator from "@/lib/Validator";

import Modal from "@/components/Modal";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import iconEye from "@/assets/icons/icon-eye.svg?inline";
import iconEyeOff from "@/assets/icons/icon-eye-off.svg?inline";
import iconChevronRight from "@/assets/icons/icon-chevron-right.svg?inline";

export default {
    components: {
        Modal,
        iconLeft,
        iconEye,
        iconEyeOff,
        iconChevronRight,
    },
    data() {
        return {
            show_password: false,
            errors: {},
            values: {
                password: "",
                newPassword: "",
                newPassword_confirmation: "",
            },
            validation: {
                newPassword: Validator.IsPassword
            }
        };
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
    },
    methods: {
        async save() {
            this.errors = Validator.Validate(this.values, this.validation);

            if (Object.keys(this.errors).length) {
                this.$toast({
                    message: Object.values(this.errors)[0],
                    type: "error",
                });
                return;
            }

            if (this.values.newPassword !== this.values.newPassword_confirmation) {
                this.errors["newPassword_confirmation"] = this.$t("Passwords do not match");
                return;
            }

            let success = await UsersApi.UpdatePassword(
                this.values.newPassword,
                this.values.newPassword_confirmation,
                this.values.password
            ).catch(err => {
                this.errors = {};

                Object.keys(err).map(key => {
                    this.errors[key] = err[key][0];
                });
            });

            if (success) {
                this.$emit("close");
                this.$toast({ type: "success", message: this.$t("Your information has been updated.") });
            }
        },
    }
};
</script>

<style lang="scss">
@import ".";
</style>
