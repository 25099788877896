import HTTPClient from "@/lib/HTTPClient";
import PlannedDayMenu from "@/models/DayMenu/PlannedDayMenu";
import {DateTime} from "@/lib/Misc";

const PlannedDayMenus = {};

PlannedDayMenus.GetAll = async () => {
    let {data, status} = await HTTPClient.Get("/day-menu-planned/all");
    data = data.data;

    if (status != 200) {
        throw new Error(data);
    }

    if (data.length === 0) {
        return [];
    }

    let result = [];

    // Looping throw object to flatten data and make it more usable
    for (const yearMonthChuck in data) {
        const yearMonth = yearMonthChuck.split("-");
        const year = parseInt(yearMonth[1]);
        const month = parseInt(yearMonth[0]) - 1; // Date object start month from 0;

        result.push({
            date: new Date(year, month, 1, 0, 0, 0, 0),
            plannedDayMenus: data[yearMonthChuck].map(plannedDayMenuData => {
                return new PlannedDayMenu(plannedDayMenuData);
            })
        });
    }

    return result;
};

PlannedDayMenus.PlanDayMenu = async (dayMenuId, plannedAt) => {
    const {data, status} = await HTTPClient.Post("/day-menu-planned", {
        data: {
            day_menu_id: dayMenuId,
            planned_at: DateTime.FormatToDate(plannedAt)
        }
    });

    if (status != 200) {
        throw new Error(data);
    }

    return new PlannedDayMenu(data.data);
};

PlannedDayMenus.UnplanDayMenu = async (plannedDayMenuId) => {
    const {data, status} = await HTTPClient.Delete(`/day-menu-planned/${plannedDayMenuId}`);

    if (status != 200) {
        throw new Error(data);
    }
};

export default PlannedDayMenus;
