<template>
    <div
        class="card"
        :class="[theme ? `-${theme}` : '']">
        <div class="card-media">
            <router-link :to="urlPath">
                <div
                    class="image"
                    :style="{ backgroundImage: `url(${imageUrl})` }"
                />
            </router-link>
        </div>
        <div class="card-content">
            <router-link :to="urlPath">
                <span class="card-title">
                    {{ title }}
                </span>
                <div class="card-details">
                    <div class="card-detail" v-if="calendarText">
                        <icon-calendar />
                        <span>{{ calendarText }}</span>
                    </div>
                    <div class="card-detail" v-if="nutritionText">
                        <icon-recipe />
                        <span>{{ nutritionText }}</span>
                    </div>
                    <div class="card-detail" v-if="kcalText">
                        <icon-kcal />
                        <span>{{ kcalText }}</span>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="card-actions" v-if="hasActions">
            <slot name="card-actions"></slot>
        </div>
    </div>
</template>

<script>
import iconKcal from "@/assets/icons/icon-kcal.svg?inline";
import iconRecipe from "@/assets/icons/icon-recipe.svg?inline";
import iconCalendar from "@/assets/icons/icon-calendar.svg?inline";

export default {
    name: "Card",
    props: {
        title: String,
        urlPath: String,
        imageUrl: String,
        kcalText: String,
        calendarText: String,
        nutritionText: String,
        theme: String,
    },
    components: {
        iconKcal,
        iconRecipe,
        iconCalendar,
    },
    computed: {
        hasActions() {
            return !!this.$slots["card-actions"];
        },
    },
};
</script>

<style lang="scss">
    @import "index";
</style>
