const ScrollTo = {};

ScrollTo.hasHash = (currentRoute) => {
    return Boolean(currentRoute.value.hash);
};

ScrollTo.toElement = (element, offset = -100) => {
    window.scroll({
        top: element.offsetTop + offset,
        left: 0,
        behavior: "smooth",
    });
};

ScrollTo.getHash = (currentRoute) => {
    if (!ScrollTo.hasHash(currentRoute)) {
        return "";
    }

    return currentRoute.value.hash.replace("#", "");
};

export default ScrollTo;
