const TypeChecker = {};

TypeChecker.isString = (value) => {
    return typeof value === "string";
};

TypeChecker.isNumber = (value) => {
    return typeof value === "number" && !isNaN(value);
};

export default TypeChecker;
