<template>
    <div class="container">
        <Header :title="$t('My Project Gezond')" :subtitle="getDate">
            <template v-slot:button-end>
                <button @click="modals.settings = true" class="btn -icon-only">
                    <icon-settings />
                </button>
            </template>
        </Header>

        <account-summary
            @click-calorie-requirement="modals.calorie_requirement = true"
            @click-user-bmi="modals.user_bmi = true"
        />

        <recent-weight-log
            @add="modals.add_weight_log = true"
            @showmore="modals.weight_log = true"
            :key="weightlog_refresh"
        />

        <!-- User progress -->
        <Progress :key="weightlog_refresh" />

        <!-- User menus -->
        <div ref="planned-daymenus">
            <account-planned-day-menus />
        </div>
        <account-favorite-recipes />
        <account-favorite-day-menus />

        <!-- Modals -->
        <settings-modal
            v-if="modals.settings"
            @close="modals.settings = false"
            @click-option="onSettingsClick"
        />

        <add-weight-log
            v-if="modals.add_weight_log"
            @close="modals.add_weight_log = false"
            @refresh="weightlog_refresh = new Date()"
        />

        <personal-info-modal
            v-if="modals.personal_info"
            @close="modals.personal_info = false"
        />

        <weight-log-modal
            v-if="modals.weight_log"
            @close="modals.weight_log = false"
            @add="modals.add_weight_log = true"
            @refresh="weightlog_refresh = new Date()"
            :key="weightlog_refresh"
        />

        <calorie-requirement-modal
            v-if="modals.calorie_requirement"
            @close="modals.calorie_requirement = false"
        />

        <user-bmi-modal
            v-if="modals.user_bmi"
            @close="modals.user_bmi = false"
        />

        <change-password-modal
            v-if="modals.change_password"
            @close="modals.change_password = false"
        />
    </div>
</template>

<script>
import DateTime from "@/lib/misc/DateTime";
import ScrollTo from "@/lib/ScrollTo";
import Header from "@/components/Header";
import AccountSummary from "./Summary";
import AccountPlannedDayMenus from "./PlannedDayMenus";
import AccountFavoriteRecipes from "./FavoriteRecipes";
import AccountFavoriteDayMenus from "./FavoriteDayMenus";
import RecentWeightLog from "./WeightLogs/RecentWeightLog";
import AddWeightLog from "./WeightLogs/AddWeightLog";
import WeightLogModal from "./WeightLogs/WeightLogModal";
import Progress from "./Progress";
import SettingsModal from "./SettingsModal";
import PersonalInfoModal from "./SettingsModal/PersonalInfoModal";
import CalorieRequirementModal from "./SettingsModal/CalorieRequirementModal";
import ChangePasswordModal from "./SettingsModal/ChangePasswordModal";
import UserBmiModal from "@/views/Account/Summary/UserBmiModal";
import iconSettings from "@/assets/icons/icon-settings.svg?inline";

export default {
    name: "Account",
    components: {
        AccountSummary,
        AccountPlannedDayMenus,
        AccountFavoriteRecipes,
        AccountFavoriteDayMenus,
        RecentWeightLog,
        AddWeightLog,
        WeightLogModal,
        Progress,
        Header,
        SettingsModal,
        PersonalInfoModal,
        ChangePasswordModal,
        CalorieRequirementModal,
        UserBmiModal,
        iconSettings,
    },
    computed: {
        getDate: () => DateTime.ConvertDateToHumanReadable(new Date()),
    },
    data() {
        return {
            modals: {
                settings: false,
                add_weight_log: false,
                weight_log: false,
                personal_info: false,
                calorie_requirement: false,
                user_bmi: false,
                change_password: false,
            },
            weightlog_refresh: null,
        };
    },
    methods: {
        onSettingsClick(key) {
            if (this.modals[key] === undefined) {
                // Modal not found
                return;
            }

            this.modals[key] = !this.modals[key];
        },
    },
    mounted() {
        const route = this.$router.currentRoute;
        const self = this;

        if (ScrollTo.hasHash(route)) {
            setTimeout(() => {
                const ref = self.$refs[ScrollTo.getHash(route)];
                ScrollTo.toElement(ref);
            }, 10);
        }
    },
};
</script>
