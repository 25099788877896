<template>
    <modal :title="$t('BMI')" class="modal-user-bmi">
        <template v-slot:modal-header-button-start>
            <button @click="$emit('close')" class="btn -icon -icon-only">
                <iconLeft />
            </button>
        </template>
        <bmi-result :bmi="user_bmi" :length="user_length" />
    </modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import BmiResult from "@/components/BmiResult";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    components: {
        Modal,
        BmiResult,
        iconLeft,
    },
    computed: {
        ...mapGetters("user", ["getUser"]),

        user_bmi() {
            return this.getUser.getBMI();
        },
        user_length() {
            return this.getUser.getHeight();
        },
    },
};
</script>

<style lang="scss">
@import ".";
</style>
