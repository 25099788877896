import UsersApi from "@/lib/api/Users";
import store from "@/store";

const WeightLogs = {
    total_pages: null,
    total: null,
    _WeightLogPages: {}
};

WeightLogs.GetLogByPage = async (page = 1) => {
    if (WeightLogs._WeightLogPages[page]) {
        return WeightLogs._WeightLogPages[page];
    }

    const body = await UsersApi.GetLog(page);
    WeightLogs._WeightLogPages[page] = body.data;
    WeightLogs.total_pages = body.last_page;
    WeightLogs.total = body.total;

    return WeightLogs._WeightLogPages[page];
};

WeightLogs.ClearCaches = () => {
    WeightLogs._WeightLogPages = {};
    store.dispatch("user/getUserInfo");
};

WeightLogs.AddLog = async (timestamp, weight) => {
    let data = await UsersApi.AddLog(timestamp, weight);

    if (data.error) {
        return data;
    }

    WeightLogs.ClearCaches();
    return data;
};

WeightLogs.RemoveLog = async (logId) => {
    let resp = await UsersApi.RemoveLog(logId);

    WeightLogs.ClearCaches();

    return resp;
};

WeightLogs.RemoveAllLogs = async () => {
    let resp = await UsersApi.RemoveAllLogs();

    WeightLogs.ClearCaches();

    return resp;
};

export default WeightLogs;
