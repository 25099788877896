<template>
    <span
        :class="`swipable ${active ? '-active' : ''} ${classes}`"
        ref="swipable"
        :style="style"
    >
        <slot />
    </span>
</template>

<script>
export default {
    data() {
        return {
            active: false,
            classes: "",
            startX: 0,
            startY: 0,
            style: {
                transform: "translateX(0px)",
            },
        };
    },

    mounted() {
        const _this = this;
        this.$refs.swipable.addEventListener(
            "touchstart",
            function(e) {
                _this.active = true;
                var touchobj = e.changedTouches[0];
                this.startX = touchobj.pageX;
                this.startY = touchobj.pageY;
            },
            false
        );

        this.$refs.swipable.addEventListener(
            "touchmove",
            function(e) {
                const touch = e.touches[0];

                this.style.transform = `translateX(${
                    touch.pageX - this.startX
                }px)`;

                let change = touch.pageX / this.startX;

                if (change > 1.5) {
                    _this.classes = "-right";
                } else if (change < 0.75) {
                    _this.classes = "-left";
                } else {
                    _this.classes = "";
                }
            },
            false
        );

        this.$refs.swipable.addEventListener(
            "touchend",
            function(e) {
                _this.active = false;
                this.style.transform = "translateX(0px)";
                const touch = e.changedTouches[0];

                let change = touch.pageX / this.startX;

                if (change > 1.5) {
                    _this.$emit("swipe-right");
                } else if (change < 0.5) {
                    _this.$emit("swipe-left");
                } else {
                    _this.classes = "";
                }
            },
            false
        );
    },
};
</script>

<style lang="scss">
@import "index";
</style>
