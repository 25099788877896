<template>
    <div class="block">
        <div class="block-title">
            <h2>{{ $t('My weight log') }}</h2>
        </div>

        <div class="block-content">
            <div class="user-weight-logs">
                <div class="btn -transparent -add" @click="$emit('add')">
                    <icon-plus />
                    {{ $t("Add weightlog") }}
                </div>
                <ul>
                    <li
                        v-for="log in getRecentLogs"
                        :key="log.getId()">
                        <dl>
                            <dt><label>{{ $t("Weight") }}</label></dt>
                            <dd><strong>{{ log.getFormattedWeight() }}</strong></dd>
                        </dl>
                        <dl>
                            <dt><label>{{ $t("Date") }}</label></dt>
                            <dd><small>{{ log.getFormattedDate() }}</small></dd>
                        </dl>
                    </li>
                </ul>
                <button class="btn -edit" @click="$emit('showmore')">{{ $t("View and manage all weightlogs") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import WeightLogs from "@/lib/WeightLogs";

import iconPlus from "@/assets/icons/icon-plus.svg?inline";

export default {
    components: {
        iconPlus
    },

    data() {
        return {
            logs: []
        };
    },

    async mounted() {
        this.logs = await WeightLogs.GetLogByPage(1);
    },

    computed: {
        getRecentLogs() {
            return this.logs.slice(0, 3);
        }
    }
};
</script>

<style lang="scss">
@import ".";
</style>
