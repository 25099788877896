<template>
    <modal
        :title="$t('Calorieneed')"
        class="modal-kcal-requirement"
        v-if="getUser"
    >
        <template v-slot:modal-header-button-start>
            <button @click="$emit('close')" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>

        <p>
            {{ $t('Based on your data your kcal requirement is') }}
            <strong>{{ calorieRequirement }} Kcal</strong>
            {{ $t('a day. To lose weight we recommend subtracting (maximum) 500 Kcal from this. You then arrive at') }}
            <strong>{{ calorieRequirementMin }} Kcal</strong>
        </p>
        <p>
            {{ $t('The variant of the weekly menu that is closest to this is that of') }}
            <strong>{{ recommendedCalorieRequirement }} Kcal</strong>
        </p>
        <br>
        <p><strong>{{ $t('Open daily menus standard in the following kcal variant:') }}</strong></p>
        <select v-model="value" @change="save">
            <option v-for="item in options" :value="item" :key="item">
                {{ item }} kcal
            </option>
        </select>
    </modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Modal from "@/components/Modal";
import UsersApi from "@/lib/api/Users";
import User from "@/models/User";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    components: {
        Modal,
        iconLeft,
    },
    data() {
        return {
            value: "",
            options: ["1250", "1500", "1800", "2100"],
        };
    },
    computed: {
        ...mapGetters("user", ["getUser"]),

        calorieRequirement() {
            return this.getUser.getCalorieRequirement();
        },
        calorieRequirementMin() {
            return this.getUser.getCalorieRequirement() - 500;
        },
        recommendedCalorieRequirement() {
            return this.getUser.getCalculatedDefaultCalorie();
        },
    },
    mounted() {
        this.value = this.getUser.getDefaultCalories();
    },
    methods: {
        ...mapMutations("user", ["setUser"]),
        async save() {
            const resp = await UsersApi.UpdateUser(
                { default_calories: this.value },
                this.getUser.getId()
            );

            this.setUser(new User(resp));
            this.$toast({
                type: "success",
                message: this.$t(
                    "Your prefered calorievariant has been set to {calories}",
                    { calories: this.value }
                ),
            });
        },
    },
};
</script>
