<template>
    <modal
        :title="getUser.getFirstName()"
        :subtitle="getUser.getEmail()"
        class-header="white"
        class="settings-modal"
        v-if="getUser"
    >
        <template v-slot:modal-header-button-start>
            <button @click="$emit('close')" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>

        <ul class="settings-menu">
            <li
                v-for="(item, key) in items"
                :key="key"
                @click="onClickedItem(key, item)"
                class="settings-menu-item"
            >
                <a>
                    <component :is="item.icon" />
                    <span>{{ item.title }}</span>
                </a>
            </li>
        </ul>

        <div class="version" v-if="currentVersion">
            <p>{{ $t("Version: {version}", { version: currentVersion }) }}</p>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import Authentication from "@/lib/Authentication";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import iconPercent from "@/assets/icons/icon-percent.svg?inline";
import iconUser from "@/assets/icons/icon-user.svg?inline";
import iconLock from "@/assets/icons/icon-lock.svg?inline";
import iconLogout from "@/assets/icons/icon-logout.svg?inline";
import iconLog from "@/assets/icons/icon-log.svg?inline";

export default {
    components: {
        Modal,
        iconLeft,
        iconPercent,
        iconLock,
        iconLogout,
        iconLog,
    },
    data() {
        return {
            items: {
                personal_info: {
                    title: this.$t("Personal Info"),
                    icon: iconUser,
                },
                calorie_requirement: {
                    title: this.$t("Calorieneed"),
                    icon: iconPercent,
                },
                weight_log: {
                    title: this.$t("My Weight Logs"),
                    icon: iconLog,
                },
                change_password: {
                    title: this.$t("Change Password"),
                    icon: iconLock,
                },
                logout: {
                    title: this.$t("Log out"),
                    icon: iconLogout,
                    cb: this.logout,
                },
            },
        };
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        currentVersion() {
            const ver = process.env.VUE_APP_VERSION;

            if (!ver) {
                return null;
            }

            return ver;
        },
    },
    methods: {
        onClickedItem(key, item) {
            if (item.cb) {
                return item.cb();
            }

            this.$emit("click-option", key);
        },
        logout() {
            Authentication.Logout();
            this.$router.push("/welkom");
        },
    },
};
</script>

<style lang="scss">
@import ".";
</style>
