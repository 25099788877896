<template>
    <div class="block" v-if="favoriteRecipes.length">
        <div class="block-title">
            <h2>{{ $t('My favorite recipes') }}</h2>
        </div>
        <div class="block-content -shadow">
            <card
                class="-list"
                v-for="recipe in favoriteRecipes"
                :key="recipe.getId()"
                :url-path="`/recepten/${recipe.getSlug()}`"
                :title="recipe.getTitle()"
                :image-url="recipe.getMedia('small')"
                :kcal-text="`${recipe.getKcal()} ${$t('Kcal')}`"
                :nutrition-text="recipe.getMealTypesString()"
            >
                <template v-slot:card-actions>
                    <button
                        @click="removeFavorite(recipe)"
                        class="btn -icon -icon-only"
                    >
                        <iconTrash />
                    </button>
                </template>
            </card>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import FavoriteHelper from "@/lib/FavoriteHelper";

import Card from "@/components/Card";
import iconTrash from "@/assets/icons/icon-trash.svg?inline";

export default {
    components: {
        Card,
        iconTrash
    },
    computed: {
        ...mapState("favorites", ["favoriteRecipes"]),
    },
    methods: {
        removeFavorite(favoriteObject) {
            FavoriteHelper.onFavoriteToggle(favoriteObject);
        }
    },
    async mounted() {
        if (!FavoriteHelper.hasRecipes) {
            await FavoriteHelper.initRecipes();
        }
    }
};
</script>

<style></style>
